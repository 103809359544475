import { ErrorHandler } from '@angular/core';

export class AppGlobalErrorhandler implements ErrorHandler {
  handleError(error) {
    if ((window as any).newrelic) {
      console.error(error);
      (window as any).newrelic.noticeError(error);
    }
  }
}
